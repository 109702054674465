











































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'Question489',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        incompleteOctet: [],
        language: 'fr',
      },
      options: [
        {text: '$\\ce{CH4}$', value: 'methane'},
        {text: '$\\ce{CH3^-}$', value: 'methylAnion'},
        {text: '$\\ce{CH3+}$', value: 'methylCation'},
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/bondIdentification1.png';
    },
  },
};
